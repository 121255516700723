import React from 'react'
import logo from '../resources/img/logo.svg'

const Header = () => {
    return (
        <>
            <div style={{ position: "absolute", top: "0", background: "rgba(5, 44, 63, 0.5)", opacity: "0.65", minHeight: "100vh", height: "100%", width: "100%" }}></div>
            <header style={{ height: "70px", width: "100%" }}>
                <div className="container" style={{ position: "relative" }}>

                    <div className="brand" style={{ zIndex: "10", float: "left", fontFamily: "Nunito", lineHeight: "75px", color: "white", height: "70px", fontSize: "1.85rem" }}>
                        <img src={logo} alt="" />
                    </div>
                    <div className="volver-sitio" style={{ float: "right", fontFamily: "Nunito", lineHeight: "70px", color: "white", height: "70px", fontSize: "1rem" }}>
                        <a style={{ textDecoration: "none", color: "white" }} href="https://rtosanmartinmendoza.com.ar">SITIO WEB</a>
                    </div>


                </div>
            </header>
        </>
    )
}

export default Header;
