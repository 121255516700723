import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Header from './header'
import { Redirect } from 'react-router'
import { setTipoVehiculo } from '../redux/actions/turnero'

const cuentaRegresiva = ['9', '8', '7', '6', '5', '4', '3', '2', '1', '0']

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const Finproceso = (props) => {

    const [segundos, setSegundos] = useState(10)
    const [redirigir, setRedirigir] = useState(false)

    const { urlPago } = props



    return (
        <div style={{ position: "relative" }}>





            <Header></Header>

            <div style={{ textAlign: "center", background: "none", color: "white", border: "0", margin: "0 auto", marginTop: "60px", lineHeight: "45px" }} className="card responsive-card">

                <p>El turno ha sido confirmado.</p>
                {/* <p>Le hemos enviado los detalles el mismo a su correo electrónico.</p> */}
                <p>¡Muchas gracias!</p>



            </div>





        </div >
    )
}


const mapStateToProps = state => ({
    urlPago: state.turnero.urlPago
})

const mapDispatchToProps = {
    setTipoVehiculo: setTipoVehiculo
}

export default connect(mapStateToProps, mapDispatchToProps)(Finproceso);
